import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './auth/auth-guard.service';

const routes: Routes = [
  { path: '', redirectTo: 'compra/selecciona-viaje', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./auth/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'compra/selecciona-viaje',
    loadChildren: () => import('./compra/selecciona-viaje/selecciona-viaje.module').then( m => m.SeleccionaViajePageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra/selecciona-horario',
    loadChildren: () => import('./compra/selecciona-horario/selecciona-horario.module').then( m => m.SeleccionaHorarioPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra/selecciona-horario/:viaje',
    loadChildren: () => import('./compra/selecciona-horario/selecciona-horario.module').then( m => m.SeleccionaHorarioPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra/selecciona-lugares',
    loadChildren: () => import('./compra/selecciona-lugares/selecciona-lugares.module').then( m => m.SeleccionaLugaresPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra/selecciona-lugares/:viaje',
    loadChildren: () => import('./compra/selecciona-lugares/selecciona-lugares.module').then( m => m.SeleccionaLugaresPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: 'compra/registrar-pasajeros',
    loadChildren: () => import('./compra/registrar-pasajeros/registrar-pasajeros.module').then( m => m.RegistrarPasajerosPageModule)
  },
  {
    path: 'compra/confirmar-reservacion',
    loadChildren: () => import('./compra/confirmar-reservacion/confirmar-reservacion.module').then( m => m.ConfirmarReservacionPageModule)
  },
  {
    path: 'viaje/reservaciones',
    loadChildren: () => import('./viaje/reservaciones/reservaciones.module').then( m => m.BoletosPageModule)
  },
  {
    path: 'viaje/reservaciones/:id',
    loadChildren: () => import('./viaje/detalle-reservacion/detalle-reservacion.module').then( m => m.DetalleBoletoPageModule)
  },
  {
    path: 'viaje/pagar-reservacion/:id',
    loadChildren: () => import('./viaje/pagar-reservacion/pagar-reservacion.module').then( m => m.PagarReservacionPageModule)
  },
  {
    path: 'perfil/perfil',
    loadChildren: () => import('./perfil/perfil/perfil.module').then( m => m.PerfilPageModule)
  },
  {
    path: 'perfil/cambiar-contrasena/:id',
    loadChildren: () => import('./perfil/cambiar-contrasena/cambiar-contrasena.module').then( m => m.CambiarContrasenaPageModule)
  },
  {
    path: 'recuperar-contrasena',
    loadChildren: () => import('./auth/recuperar-contrasena/recuperar-contrasena.module').then( m => m.RecuperarContrasenaPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
