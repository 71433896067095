import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor(
    private navController: NavController,
    ) { }

  ngOnInit() {}

  irComprar() {
    this.navController.navigateRoot('/compra/selecciona-viaje');
  }
  irMisViajes() {
    this.navController.navigateRoot('/viaje/reservaciones');
  }
  irPerfil() {
    this.navController.navigateRoot('/perfil/perfil');
  }
}
