export class Ciudad {

    private _nombre: string;
    private _clave: string;

    constructor(nombre: string, clave: string) {
        this._nombre = nombre;
        this._clave = clave;
    }

    public get nombre(): string {
        return this._nombre;
    }

    public set nombre(nombre: string) {
        this._nombre = nombre;
    }

    public get clave(): string {
        return this._clave;
    }

    public set clave(clave: string) {
        this._clave = clave;
    }
}
