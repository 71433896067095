import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { take, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import firebase from '@firebase/app';
import '@firebase/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  constructor(private router: Router,
              private authService: AuthService,
              private zone: NgZone) {}

  canActivate(next: ActivatedRouteSnapshot,
              state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

    firebase.auth().onAuthStateChanged(firebaseUser => {

                  this.zone.run(() => {

                    this.authService.getToken().then((token) => {

                      if (firebaseUser || token) {

                        return true;
                      } else {

                        this.router.navigateByUrl('login');
                        return false;
                      }
                    });
                  });
    });
    return true;
  }
}
