// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  FIREBASE_API_KEY: 'AIzaSyDNQYcauP-Mb4D0bHHvKsVFsypwFEox3jo',
  FIREBASE_AUTH_DOMAIN: 'nativescript-aubiasi.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://nativescript-aubiasi.firebaseio.com',
  FIREBASE_PROJECT_ID: 'nativescript-aubiasi',
  FIREBASE_STORAGE_BUCKET: 'nativescript-aubiasi.appspot.com',
  FIREBASE_MESSAGING_SENDER_ID: '1098042907689',
  FIREBASE_APP_ID: '1:1098042907689:web:c7876766f770b4f3181038',
  FIREBASE_MEASUREMENT_ID: 'G-5N70X4B4XJ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
