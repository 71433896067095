

export class User {

    private _id: number;
    private _nombre: string;
    private _email: string;
    private _token: string;

    constructor(id: number= -1, nombre: string = '', email: string, token: string) {
        this._id = id;
        this._nombre = nombre;
        this._email = email;
        this._token = token;
    }

    public get id(): number {
        return this._id;
    }

    public set id(id: number) {
        this._id = id;
    }

    public get nombre(): string {
        return this._nombre;
    }

    public set nombre(nombre: string) {
        this._nombre = nombre;
    }

    public get email(): string {
        return this._email;
    }

    public set email(email: string) {
        this._email = email;
    }

    public get token(): string {
        return this._token;
    }

    public set token(token: string) {
        this._token = token;
    }

}
