import { Pasajero } from './pasajero.model';
import { Viaje } from './viaje.model';

export class Reservacion {
    private _id: number;
    private _tipoBoleto: string;
    private _viajeIda: Viaje;
    private _viajeVuelta: Viaje;
    private _numPasajeros: number;
    private _asientosIda: number[];
    private _asientosVuelta: number[];
    private _pasajeros: Pasajero[];
    private _pagado: boolean;
    private _boleto: string;

    constructor(idReservacion: number,
                tipoBoleto: string,
                viajeIda: Viaje,
                viajeVuelta: Viaje,
                numPasajeros: number,
                asientosIda: number[],
                asientosVuelta: number[],
                pasajeros: Pasajero[],
                pagado: boolean,
                boleto: string) {
        this._id = idReservacion;
        this._tipoBoleto = tipoBoleto;
        this._viajeIda = viajeIda;
        this._viajeVuelta = viajeVuelta;
        this._numPasajeros = numPasajeros;
        this._asientosIda = asientosIda;
        this._asientosVuelta = asientosVuelta;
        this._pasajeros = pasajeros;
        this._pagado = pagado;
        this._boleto = 'data:image/png;base64,' + boleto;
    }

    public get idReservacion(): number {

        return this._id;
    }

    public set idReservacion(idReservacion: number) {

        this._id = idReservacion;
    }

    public get tipoBoleto(): string {

        return this._tipoBoleto;
    }

    public set tipoBoleto(tipoBoleto: string) {

        this._tipoBoleto = tipoBoleto;
    }

    public get viajeIda(): Viaje {

        return this._viajeIda;
    }

    public set viajeIda(viajeIda: Viaje) {

        this._viajeIda = viajeIda;
    }

    public get viajeVuelta(): Viaje {

        return this._viajeVuelta;
    }

    public set viajeVuelta(viajeVuelta: Viaje) {

        this._viajeVuelta = viajeVuelta;
    }

    public get numPasajeros(): number {

        return this._numPasajeros;
    }

    public set numPasajeros(numPasajeros: number) {

        this._numPasajeros = numPasajeros;
    }

    public get asientosIda(): number[] {

        return this._asientosIda;
    }

    public set asientosIda(asientosIda: number[]) {

        this._asientosIda = asientosIda;
    }

    public get asientosVuelta(): number[] {
        return this._asientosVuelta;
    }

    public set asientosVuelta(asientosVuelta: number[]) {
        this._asientosVuelta = asientosVuelta;
    }

    public get pasajeros(): Pasajero[] {
        return this._pasajeros;
    }

    public set pasajeros(pasajeros: Pasajero[]) {
        this._pasajeros = pasajeros;
    }

    public get pagado(): boolean {
        return this._pagado;
    }

    public set pagado(pagado: boolean) {
        this.pagado = pagado;
    }

    public get boleto(): string {
        return this._boleto;
    }

    public set boleto(boleto: string) {
        this._boleto = boleto;
    }
}
