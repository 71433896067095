import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { Facebook } from '@ionic-native/facebook/ngx';

import { tap, share, catchError } from 'rxjs/operators';
import { Observable, BehaviorSubject, of, throwError } from 'rxjs';

import firebase from '@firebase/app';
import '@firebase/auth';

import { AuthResponse } from './auth-response';
import { environment } from '../../environments/environment';
import { LoginRequest } from './login/login-request.model';
import { User } from '../models/user.model';
import { SignUpRequest } from './register/signup-request.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  AUTH_SERVER_ADDRESS = 'http://localhost:3000';

  public loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public user: User;
  public userSource: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);

  constructor(private httpClient: HttpClient,
              private storage: Storage,
              private platform: Platform,
              private zone: NgZone,
              private facebook: Facebook) { }

  public init(): void {
    // Configuracion inicial de fireBase
    const firebaseConfig = {
      apiKey: environment.FIREBASE_API_KEY,
      authDomain: environment.FIREBASE_AUTH_DOMAIN,
      databaseURL: environment.FIREBASE_DATABASE_URL,
      projectId: environment.FIREBASE_PROJECT_ID,
      storageBucket: environment.FIREBASE_STORAGE_BUCKET,
      messagingSenderId: environment.FIREBASE_MESSAGING_SENDER_ID,
      appId: environment.FIREBASE_APP_ID,
      measurementId: environment.FIREBASE_MEASUREMENT_ID

    };

    // inicializacion de fireBase
    firebase.initializeApp(firebaseConfig);
    // emitir el estatus de login cada vez que haya un cambio en la autenticacion
    firebase.auth().onAuthStateChanged(firebaseUser => {

      this.zone.run(() => {

        this.getToken().then((token) => {

          if ( firebaseUser && token && this.loggedIn.getValue() === false) {

            this.loadUser(token);
            this.loggedIn.next(true);

          } else if ((firebaseUser === undefined || token === undefined) && this.loggedIn.getValue()) {

            this.loggedIn.next(false);
          }
        });
      });
    });

    this.getToken().then(async (token) => {

      if (token && !this.loggedIn.getValue()) {
        this.loadUser(token);
        this.loggedIn.next(true);
      }
    });

  }

  public async facebookLogin(): Promise<void> {

    if (this.platform.is('capacitor')) {

      return await this.nativeFacebookAuth();
    } else {

      return await this.browserFacebookAuth();
    }

  }

  public register(request: SignUpRequest): Observable<AuthResponse> {

    const address = this.AUTH_SERVER_ADDRESS + '/registrar';
    const body = { nombre: request.name, email: request.email, password: request.password };

    return this.httpClient.post<AuthResponse>(address, body).pipe(
      tap((response: AuthResponse) => {
      }),
      catchError(this.handleError)
    );
  }

  public login(request: LoginRequest, fbLogin: boolean): Observable<AuthResponse> {

    let body: any;
    let address: string;
    if (fbLogin) {

      body = { email: request.email, name: request.name, fb_token: request.fbToken };
      address = this.AUTH_SERVER_ADDRESS + '/fb-login';
    } else {

      body = { email: request.email, password: request.password};
      address = this.AUTH_SERVER_ADDRESS + '/login';
    }

    return this.httpClient.post<AuthResponse>(address, body).pipe(

      tap(async (response: AuthResponse) => {

        const token = response.token.split('.')[0];
        await this.storage.set('ACCESS_TOKEN', token);
        this.loadUser(token);
        this.loggedIn.next(true);
      }),
      catchError(this.handleError)
    );
  }

  public async logout(): Promise<void> {

    if (this.platform.is('capacitor')) {

      try {

        await this.facebook.logout(); // Unauth with Facebook
        await firebase.auth().signOut(); // Unauth with Firebase
        await this.storage.remove('ACCESS_TOKEN');
        delete this.user;
        this.loggedIn.next(false);
      } catch (err) {

        console.log(err);
      }
    } else {

      try {

        await firebase.auth().signOut();
        await this.storage.remove('ACCESS_TOKEN');
        delete this.user;
        this.loggedIn.next(false);
      } catch (err) {

        console.log(err);
      }
    }
  }

  public async nativeFacebookAuth(): Promise<void> {

    try {

      const response = await this.facebook.login(['public_profile', 'email']);

      console.log(response);

      if (response.authResponse) {

        // El usuario ya inicio sesion en facebook
        const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
          unsubscribe();

          // Revisar si ya se inicio sesion en fireBase con el usuario correcto
          if (!this.isUserEqual(response.authResponse, firebaseUser)) {

            // Construir una credencial en firebase usando la credencial de facebook
            const credential = firebase.auth.FacebookAuthProvider.credential(
              response.authResponse.accessToken
            );
            // Iniciar sesion con la credencial de facebook
            firebase
              .auth()
              .signInWithCredential(credential)
              .catch(error => {
                console.log(error);
              });
          } else {

            // El usuario ya inicio sesion con este usuario de firebase
            console.log('ya inicio sesion');
          }
        });
      } else {

        // El usuario cerro sesion en facebook
        firebase.auth().signOut();
      }
    } catch (error) {

      throw error;
    }
  }

  public async browserFacebookAuth(): Promise<any> {

    const provider = new firebase.auth.FacebookAuthProvider();

    try {

      const result = await firebase.auth().signInWithPopup(provider);

      const fbName = result.user.displayName;
      const fbEmail = result.user.email;
      const fbToken = result.user.uid;
      const request = new LoginRequest(fbEmail, '', fbName, fbToken);

      await this.login(request, true).toPromise();

    } catch (error) {

      throw error;
    }
  }

  public changePassword(idUsuario: number, contrasenaActual: string | Int32Array, _nuevaContrasena: string | Int32Array) {

    const request = { id: idUsuario,
                      contrasena: contrasenaActual,
                      nuevaContrasena: _nuevaContrasena
                    };
    console.log('TODO: cambiar contraseña en servidor con parametros: ', request);
  }

  public recoverPassword(_email: string) {

    const request = { email: _email};

    console.log('TODO: enviar correo para restaurar contraseña desde servidor con token');
  }

  public isUserEqual(facebookAuthResponse, firebaseUser): boolean {

    if (firebaseUser) {

      const providerData = firebaseUser.providerData;

      providerData.forEach(data => {
        if (
          data.providerId === firebase.auth.FacebookAuthProvider.PROVIDER_ID &&
          data.uid === facebookAuthResponse.userID
        ) {
          // No necesitamos reautenticar la sesion en fireBase
          return true;
        }
      });
    }

    return false;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {

    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.error);
  }

  public async getToken(): Promise<string> {

    try {

      const token = await this.storage.get('ACCESS_TOKEN');
      if (token) {

        return token;
      } else {

        return undefined;
      }
    } catch (error) {

      console.log(error);
      return undefined;
    }

  }

  public loadUser(token: string): void {

    if (this.user === undefined) {

      const decodedUser = JSON.parse(atob(token));
      this.user = new User(decodedUser.idUsuario, decodedUser.nombre, decodedUser.email, token);
      this.userSource.next(this.user);
    }
  }

  public isLoggedIn(): Observable<boolean> {

    return this.loggedIn.asObservable().pipe(share());
  }

  public getUser(): Observable<User> {
    return this.userSource.asObservable().pipe(share());
  }

}
