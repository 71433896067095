import { Pasajero } from './pasajero.model';
import { Ciudad } from './ciudad.model';

export class Compra {
    private _id: number;
    private _tipoBoleto: string;
    private _origen: Ciudad;
    private _destino: Ciudad;
    private _idViajeIda: number;
    private _idViajeVuelta: number;
    private _fechaIda: Date;
    private _fechaVuelta: Date;
    private _numPasajeros: number;
    private _asientosIda: number[];
    private _asientosVuelta: number[];
    private _pasajeros: Pasajero[];
    private _pagado: boolean;
    private _boleto: string;

    constructor(id: number = -1,
                tipoBoleto: string = 'sencillo',
                origen: Ciudad = new Ciudad('', ''),
                destino: Ciudad = new Ciudad('', ''),
                idViajeIda: number = 0,
                idViajeVuelta: number = null,
                fechaIda: Date = null,
                fechaVuelta: Date = null,
                numPasajeros: number,
                asientosIda: number[] = [],
                asientosVuelta: number[] = [],
                pasajeros: Pasajero[] = [],
                pagado: boolean = false,
                boleto: string = '') {
        this._id = id;
        this._tipoBoleto = tipoBoleto;
        this._origen = origen;
        this._destino = destino;
        this._idViajeIda = idViajeIda;
        this._idViajeVuelta = idViajeVuelta;
        this._fechaIda = fechaIda;
        this._fechaVuelta = fechaVuelta;
        this._numPasajeros = numPasajeros;
        this._asientosIda = asientosIda;
        this._asientosVuelta = asientosVuelta;
        this._pasajeros = pasajeros;
        this._pagado = pagado;
        this._boleto = 'data:image/png;base64,' + boleto;
    }

    public get id(): number {

        return this._id;
    }

    public set id(id: number) {

        this._id = id;
    }

    public get tipoBoleto(): string {

        return this._tipoBoleto;
    }

    public set tipoBoleto(tipoBoleto: string) {

        this._tipoBoleto = tipoBoleto;
    }

    public get idViajeIda(): number {

        return this._idViajeIda;
    }

    public set idViajeIda(idViajeIda: number) {

        this._idViajeIda = idViajeIda;
    }

    public get idViajeVuelta(): number {

        return this._idViajeVuelta;
    }

    public set idViajeVuelta(idViajeVuelta: number) {

        this._idViajeVuelta = idViajeVuelta;
    }

    public get fechaIda(): Date {

        return this._fechaIda;
    }

    public set fechaIda(fechaIda: Date) {

        this._fechaIda = fechaIda;
    }

    public get fechaVuelta(): Date {

        return this._fechaVuelta;
    }

    public set fechaVuelta(fechaVuelta: Date) {

        this._fechaVuelta = fechaVuelta;
    }

    public get origen(): Ciudad {

        return this._origen;
    }

    public set origen(origen: Ciudad) {

        this._origen = origen;
    }

    public get destino(): Ciudad {

        return this._destino;
    }

    public set destino(destino: Ciudad) {

        this._destino = destino;
    }

    public get numPasajeros(): number {

        return this._numPasajeros;
    }

    public set numPasajeros(numPasajeros: number) {

        this._numPasajeros = numPasajeros;
    }

    public get asientosIda(): number[] {

        return this._asientosIda;
    }

    public set asientosIda(asientosIda: number[]) {

        this._asientosIda = asientosIda;
    }

    public get asientosVuelta(): number[] {
        return this._asientosVuelta;
    }

    public set asientosVuelta(asientosVuelta: number[]) {
        this._asientosVuelta = asientosVuelta;
    }

    public get pasajeros(): Pasajero[] {
        return this._pasajeros;
    }

    public set pasajeros(pasajeros: Pasajero[]) {
        this._pasajeros = pasajeros;
    }

    public get pagado(): boolean {
        return this._pagado;
    }

    public set pagado(pagado: boolean) {
        this.pagado = pagado;
    }

    public get boleto(): string {
        return this._boleto;
    }

    public set boleto(boleto: string) {
        this._boleto = boleto;
    }
}
