import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormGroup } from '@angular/forms';

import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { share, catchError } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';
import { Ruta } from '../models/ruta.model';
import { Ciudad } from '../models/ciudad.model';
import { Compra } from '../models/compra.model';
import { User } from '../models/user.model';
import { Viaje } from '../models/viaje.model';
import { Pasajero } from '../models/pasajero.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Horario } from '../models/horario.model';


@Injectable({
  providedIn: 'root'
})
export class CompraService {

  private _estadoReservacionSource: BehaviorSubject<Compra>;

  public usuario: User;

  public compra: Compra;

  public origen: Ciudad;
  public destino: Ciudad;
  public fechaIda: Date;
  public fechaVuelta: Date;
  public tipoBoleto: string;
  public numPasajeros: number;

  AUTH_SERVER_ADDRESS = 'http://localhost:3000';

  constructor(
    private authService: AuthService,
    private httpClient: HttpClient
  ) {

    this.compra = new Compra(-1, 'sencillo', undefined, undefined, -1, -1, null, null, 1, [], [], [], false, '');

    this._estadoReservacionSource = new BehaviorSubject(this.compra);

    this.authService.getUser().subscribe( user => {
      this.usuario = user;
    });

  }


  public get estadoReservacion(): Observable<Compra> {

    return this._estadoReservacionSource.asObservable().pipe(share());
  }

  public getOrigenes(): Observable<Ciudad[]> {

    const address = `${ this.AUTH_SERVER_ADDRESS }/ciudades`;

    return this.httpClient.get<Ciudad[]>(address).pipe(
      catchError(this.handleError)
    );

  }

  public getDestinos(clave: string): Observable<Ruta> {

    const address = `${ this.AUTH_SERVER_ADDRESS }/rutas/${ clave }`;

    return this.httpClient.get<Ruta>(address).pipe(
      catchError(this.handleError)
    );

  }

  public registrarViaje(origen: Ciudad,
                        destino: Ciudad,
                        tipoBoleto: string,
                        fechaIda: Date,
                        fechaVuelta: Date,
                        numPasajeros: number) {

    this.origen = origen;
    this.destino = destino;
    this.tipoBoleto = tipoBoleto;
    this.fechaIda = fechaIda;
    this.fechaVuelta = fechaVuelta !== null ?
      fechaVuelta
      : null;
    this.numPasajeros = numPasajeros;
  }

  public getHorarios(tipoViaje: string): Observable<Horario[]> {

    if (tipoViaje === 'ida') {
      const fecha = formatDate(new Date(this.fechaIda), 'yyyy-MM-dd-HH-mm', 'es-MX');

      const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/horarios/${ fecha }/${ this.origen.clave }/${ this.destino.clave }`;

      return this.httpClient.get<Horario[]>(address).pipe(
        catchError(this.handleError)
      );
    } else if (tipoViaje === 'vuelta') {

      const fecha = formatDate(new Date(this.fechaVuelta), 'yyyy-MM-dd-HH-mm', 'es-MX');
      const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/horarios/${ fecha }/${ this.destino.clave }/${ this.origen.clave }`;

      return this.httpClient.get<Horario[]>(address).pipe(
        catchError(this.handleError)
      );
    }
  }

  public registrarFecha(viaje: string, horario: Horario) {

    if (viaje === 'ida') {
      this.compra.fechaIda = horario.fecha;
      this.compra.idViajeIda = parseInt(horario.idViaje, 10);
      this.compra.origen = this.origen;
      this.compra.destino = this.destino;
      this.compra.numPasajeros = this.numPasajeros;
      this.compra.tipoBoleto = this.tipoBoleto;
    } else {
      this.compra.fechaVuelta = horario.fecha;
      this.compra.idViajeVuelta = parseInt(horario.idViaje, 10);
    }
  }

  public getViaje(tipoViaje: string): Observable<Viaje> {
    if (tipoViaje === 'ida') {
      const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/${ this.compra.idViajeIda }`;

      return this.httpClient.get<Viaje>(address).pipe(
        catchError(this.handleError)
      );
    } else if (tipoViaje === 'vuelta') {

      const address = `${ this.AUTH_SERVER_ADDRESS }/viajes/${ this.compra.idViajeVuelta }`;

      return this.httpClient.get<Viaje>(address).pipe(
        catchError(this.handleError)
      );
    }
  }

  public seleccionaLugar(idViaje: number, idAsiento: number, accion: string): Observable<any> {

    let address: string;
    const body: any = {};

    switch (accion) {

      case 'agregar':
        address = `${ this.AUTH_SERVER_ADDRESS }/asientos/estado/${ idViaje }/${ idAsiento }`;
        body.idUsuario = this.usuario.id;
        body.estado = 1;
        break;

      case 'quitar':
        address = `${ this.AUTH_SERVER_ADDRESS }/asientos/${ idViaje }/${ idAsiento }`;
    }
    return this.httpClient.put(address, body).pipe(
      catchError(this.handleError)
    );
  }

  public registrarLugares(lugaresSeleccionados: number[], tipoViaje: string) {

    if (tipoViaje === 'ida') {
      this.compra.asientosIda = lugaresSeleccionados;
    } else {
      this.compra.asientosVuelta = lugaresSeleccionados;
    }
  }

  public registrarPasajeros(pasajeros: Pasajero[]) {

    this.compra.pasajeros = pasajeros;

  }

  public hacerReservacion(): Observable<any> {

    const address = `${ this.AUTH_SERVER_ADDRESS }/reservaciones/`;
    this.compra.idViajeVuelta = this.compra.idViajeVuelta === -1 ? null :  this.compra.idViajeVuelta;

    const body = {
      idUsuario: this.usuario.id,
      tipoBoleto: this.compra.tipoBoleto,
      idViajeIda: this.compra.idViajeIda,
      idViajeVuelta: this.compra.idViajeVuelta,
      asientosIda: this.compra.asientosIda,
      asientosVuelta: this.compra.asientosVuelta,
      numPasajeros: this.compra.numPasajeros,
      pasajeros: this.compra.pasajeros
    };
    console.log(body);
    return this.httpClient.post(address, body).pipe(
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {

    if (error.error instanceof ErrorEvent) {

      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {

      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error.error}`);
    }
    // return an observable with a Ruta-facing error message
    return throwError(error.error.error);
  }

}
