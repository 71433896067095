import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { QRCodeModule } from 'angularx-qrcode';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ViajeComponent } from './viaje/viaje.component';
import { CompraComponent } from './compra/compra.component';
import { ReservacionComponent } from './reservacion/reservacion.component';





@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ViajeComponent,
    CompraComponent,
    ReservacionComponent
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    ViajeComponent,
    CompraComponent,
    ReservacionComponent
  ],
  imports: [
    IonicModule,
    CommonModule,
    QRCodeModule
  ]
})
export class ComponentsModule { }
