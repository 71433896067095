import { Component, OnInit, Input } from '@angular/core';
import { Reservacion } from 'src/app/models/reservacion.model';
@Component({
  selector: 'app-reservacion',
  templateUrl: './reservacion.component.html',
  styleUrls: ['./reservacion.component.scss'],
})
export class ReservacionComponent implements OnInit {

  @Input() reservacion: Reservacion;

  constructor() { }

  ngOnInit() {}

  esBoleto(detalles: Reservacion): boolean {

    return detalles.pagado;
  }

}
