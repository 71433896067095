import { Component, OnInit, Input } from '@angular/core';
import { CompraService } from 'src/app/compra/compra.service';

@Component({
  selector: 'app-viaje',
  templateUrl: './viaje.component.html',
  styleUrls: ['./viaje.component.scss'],
})
export class ViajeComponent implements OnInit {

  @Input() fecha: Date;
  @Input() origen: string;
  @Input() destino: string;
  public fechaFormat: Date;
  constructor(private compraService: CompraService) { }

  ngOnInit() {
  }

}
